import { useEffect, useState } from "react";

function App() {

  const [error, setError] = useState(null);


  function MuestroMovimientos() {

    const urlFetch = 'https://adm.pacagua.com/Movs/PorCuenta/1/20220801/20221231/0';
    fetch(urlFetch, {
      method: 'GET',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'text/plain'
        //'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    )
      .then((resp) => resp.json())
      .then((data) => {
        //setItems(data.Movs)
        console.log(data.Movs)
      }
      )
      // Nota: es importante manejar errores aquí y no en 
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      .catch(() => {
        setError(false)
      }
      );
  }


  useEffect(() => {

    console.log("en el useEffect")

    const urlFetch = 'https://adm.pacagua.com/Login/Login';
    var formData = new FormData();
    formData.append('Credenciales', '{ "Usuario": "gusx", "Password": "gusx" }');

    fetch(urlFetch, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setError(false)
        console.log(data)
      }
      )
      // Nota: es importante manejar errores aquí y no en 
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      .catch(() => {
        setError(false)
      }
      );

  }, []);

  return (
    <div className="App">

      text-php
      <button onClick={MuestroMovimientos}>MOSTRAR MOVIMIENTOS</button>

    </div>
  );
}

export default App;
